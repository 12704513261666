
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';
import { Watch } from 'vue-property-decorator';
import { LoginServices } from '@/services/LoginServices';
import store from '@/store';
import * as OM from '@/Model';

@Options({})
export default class HostessLayout extends Vue {

    selectedLanguage: string;
    availableLanguages: OM.TextIdentifier[] = [];
    loggedUser: OM.LoggedUserVM = new OM.LoggedUserVM();

    created() {
        this.loggedUser = StorageServices.getLoggedUser();
        this.selectedLanguage = this.loggedUser.preferredCulture;
        this.availableLanguages = this.$localizationService.getEnabledLanguageList();
    }

    @Watch('selectedLanguage')
    updateLanguage() {
        this.setLanguage();
    }

    setLanguage(){
        var loggedUser = store.state.loggedUser;
        loggedUser.preferredCulture = this.selectedLanguage;

        StorageServices.setLoggedUser(loggedUser);
        store.state.loggedUser = loggedUser;
    }

    logout() {
        LoginServices.logout(true);
    }

}
